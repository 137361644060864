import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'openAccount',
    component: () => import('@/views/openAccount.vue')
  },
  {
    path: '/admin/',
    name: 'Login',
    component: () => import('@/views/admin/Login.vue')
  },
  {
    path: '/admin/home',
    name: 'Home',
    component: () => import('@/views/admin/Home.vue')
  },
  {
    path: '/riskTip',
    name: 'RiskTip',
    component: () => import('@/views/questionnaire/riskTip.vue')
  },
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: () => import('@/views/questionnaire/questionnaire.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/views/questionnaire/result.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
